@font-face {
  font-family: "brand-font";
  src: url("../assets/fonts/COOPBL.ttf");
}
@font-face {
  font-family: "reg";
  src: url("../assets/fonts/Inter-Regular.ttf");
}
@font-face {
  font-family: "bold";
  src: url("../assets/fonts/Inter-Bold.ttf");
}
@font-face {
  font-family: "extreme-bold";
  src: url("../assets/fonts/Inter-ExtraBold.ttf");
}
:root {
  --light-font: "light";
  --regular-font: "reg";
  --semi-bold-font: "semi-bold";
  --bold-font: "bold";
  --extreme-bold-font: "extreme-bold";
  --brand-font: "brand-font";
  --brand-color: #007aff;
  --light-brand-color: #ecf2ff;
  --blue: #007aff;
  --white: white;
  --black: black;
  --shadow: rgba(0, 0, 0, 0.12) 0 0 16px 0;
  --light-borded-shadow: rgba(0, 0, 0, 0.6) 0 0 2px 0;
  --shadow-thick: rgba(0, 0, 0, 0.3) 0 0 16px 0;
  --font-size: 14px;
  --font-lg: 30px;
  --medium-font-size: 12.5px;
  --small-font-size: 10px;
  --header-width: 100%;
  --header-height: 70px;
  --tab-bar-height: 40px;
  --content-width: 980px;
  --scrollbar-width: 0.6vw;
  --circle: 50%;
  --space: 10px;
  --space-md: 15px;
  --space-lg: 20px;
  --logo-size: 50px;
  --min-radius: 8px;
  --radius: 16px;
  --app-logo-size: 45px;
  --small-width: 200px;
}

.light {
  --background: #ffffff;
  --content-bg: #f9f9fd;
  --background-shade: #fafafa;
  --tintBackgroundColor: #e9ecef;
  --color: #535c65;
  --color-light: #8896a5;
  --bold-color: black;
  --shade: #e8e8e8;
  --tintBorderColor: #dee2e6;
  --pressHiglight: #f3f3f3;
  --border-color: #e9ecef;
  --placeholder: #9c9c9c;
  --sidebar-bg: #ffffff;
  --hover-bg: #f7f7f7;
  --hover-color: var(--color);
  --active-btn-bg: #ecf6ff;
  --blue-tint: #ecf6ff;
  --scrollbar-thumb-color: #999999;
  --focused-input-color: #a5c9f5;
  --search-input-bg: #eeeef3;
  --green: #4ed164;
  --red: #ff5d5d;
  --light-red: #ff9898;
}

.dark {
  --background: #2a3139;
  --content-bg: #323d48;
  --background-shade: #1a1b1d;
  --tintBackgroundColor: #22282f;
  --color: white;
  --bold-color: white;
  --border-color-2: #3e3e3e;
  --color-light: #cdcdcd;
  --shade: #343a40;
  --tintBorderColor: #495057;
  --pressHiglight: #1b1e22;
  --border-color: #343a40;
  --placeholder: #8a8a8a;
  --sidebar-bg: #1f2326;
  --hover-bg: rgb(42, 42, 42);
  --hover-color: var(--color);
  --active-btn-bg: #ecf6ff;
  --blue-tint: #1e2b36;
  --scrollbar-thumb-color: #454d54;
  --focused-input-color: #5b6f89;
  --search-input-bg: #282c30;
  --green: #4ed164;
  --red: #c76262;
  --light-red: #ff9898;
  --light-brand-color: #282f3d;
}

.footer .ft-content, .al-banner, .contact .fdc, .contact .ct-con-lists, .contact .ct-exp, .job, .list-card .graphic, .features .feature .ft-graphic, .fancy-feature-connector, .fancy-feature-hero .fancy-card .fn-actions, .fancy-feature-hero, .hero-container .hero-label .icon, .hero-container .hero-graphic, .hero-container .hero-left, .badge, .avatar .av-placeholder, .scflogo, .button, .center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header .hd-bucket {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.money .mn-cash, .hero-container .hero-label, .header .hd-left, .header, .input-container .input-left,
.input-container .input-right, .click .clk-icon, .click, .logo {
  display: flex;
  align-items: center;
}

.input-container, .list-item .li-subtitle-text, .list-item .list-left {
  display: flex;
  align-items: center;
}

.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.hero-container .hero-content, .header .hd-bucket {
  width: var(--content-width);
  max-width: var(--content-width);
}

.appointment, .hero-container, .header {
  cursor: default;
}

body {
  font-family: var(--regular-font);
  font-size: 14px;
  background: var(--content-bg);
  color: var(--color);
  margin: 0;
  scrollbar-width: var(--scrollbar-width);
  scrollbar-color: var(--tintBackgroundColor) var(--pressHiglight);
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: var(--scrollbar-width);
}

::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-color);
  border-radius: var(--min-radius);
  border: 2px solid var(--content-bg);
}

::-webkit-scrollbar-track {
  background: transparent;
}

* {
  box-sizing: border-box;
  text-decoration: none;
}

a {
  text-decoration: none;
  color: inherit;
}

.flex {
  display: flex;
}

.logo .logo-text {
  font-family: var(--brand-font);
  color: var(--blue);
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.list-item {
  padding: var(--space);
  display: flex;
  align-items: center;
  border-radius: var(--min-radius);
  justify-content: space-between;
  background: var(--background);
}
.list-item .list-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
.list-item .vertical {
  width: 100%;
}
.list-item .li-title {
  font-family: var(--bold-font);
  color: var(--bold-color);
}
.list-item .li-title span {
  color: #ac8c0c;
}
.list-item .list-right {
  padding-left: var(--space);
}
.list-item .list-left {
  padding-right: var(--space);
}
.list-item .li-subtitle-text {
  font-size: var(--medium-font-size);
  color: var(--color-light);
}

.list-item.testim img {
  filter: invert(1);
}

.list-item.clickable {
  cursor: pointer;
  transition: all 0.3s;
}

.list-item.clickable:hover {
  background: var(--background-shade);
}

.click {
  cursor: pointer;
  padding: var(--space);
  transition: all 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.click:hover {
  transition: all 0.3s;
  opacity: 0.7 !important;
}

.click.link {
  color: var(--blue);
}
.click.link svg {
  color: var(--blue);
}

.click.tint {
  color: var(--blue);
  background: var(--blue-tint);
  border-radius: var(--min-radius);
}
.click.tint svg {
  color: var(--blue) !important;
}

.click.regular {
  color: var(--color);
}
.click.regular svg {
  color: var(--color);
}

.click.navlink {
  padding: var(--space-md) 10px;
  color: var(--color-light);
  border-radius: var(--min-radius);
  font-size: 12.5px;
  margin-bottom: var(--space);
}
.click.navlink svg path {
  stroke: var(--color-light);
}

.click.navlink:hover {
  background: var(--hover-bg);
}

.link-active .click {
  background: var(--blue-tint) !important;
  border-radius: var(--min-radius);
}
.link-active .click * {
  color: var(--blue) !important;
}

.button {
  padding: var(--space);
  cursor: pointer;
  transition: all;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  font-size: var(--medium-font-size);
  transition: all 0.3s;
}

.button:active {
  scale: 1.09;
}

.button:hover {
  opacity: 0.8;
}

.button.solid {
  background-color: var(--blue);
  border-radius: var(--min-radius);
  font-size: 12px;
  color: var(--white);
  font-family: var(--bold-font);
}
.button.solid svg {
  color: var(--white);
}

.button.link {
  color: var(--blue);
  font-family: var(--bold-font);
  font-size: 13.5px;
}
.button.link svg path {
  fill: var(--blue);
}

.btn-icon {
  background: var(--background);
  border-radius: var(--circle);
  border: 1px solid var(--border-color);
}

.scflogo {
  justify-content: flex-start;
  height: calc(var(--header-height) - 40px);
}
.scflogo img {
  height: 100%;
}

.input-container {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.17);
  max-width: 600px;
  border-radius: var(--min-radius);
  overflow: hidden;
  padding: 0 var(--space);
  background: var(--background);
  border: 2px solid var(--border-color);
}
.input-container .input-left {
  padding-right: var(--space);
}
.input-container .input-right {
  padding-left: var(--space);
}
.input-container input {
  outline: none;
  border: 0;
  font-family: var(--regular-font);
  padding: var(--space) 0;
  width: 100%;
  background: transparent;
  color: var(--bold-color);
}

.input-container:focus-within {
  border: 2px solid var(--brand-color);
}

.avatar {
  background: var(--tintBackgroundColor);
  border-radius: var(--circle);
  position: relative;
}
.avatar .av-image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  border-radius: var(--circle);
  overflow: hidden;
}
.avatar .av-image img {
  width: 100%;
}
.avatar .av-placeholder {
  width: 100%;
  height: 100%;
  border-radius: var(--circle);
}
.avatar .av-title {
  font-family: "bold";
  text-align: center;
  text-transform: uppercase;
}

.badge {
  width: 45px;
  height: 45px;
  background: var(--blue);
  border-radius: var(--min-radius);
}

.modal {
  z-index: 100;
}

.blur-bg {
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}

.transparent-bg {
  background: rgba(0, 0, 0, 0.6);
}

.menu-item {
  background: transparent;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.menu-item .li-subtitle-text {
  font-size: var(--medium-font-size);
  padding-top: 5px;
}

.menu-link {
  padding-top: 0;
  padding-bottom: 0;
  height: 35px;
  overflow: hidden;
}
.menu-link * {
  color: var(--blue) !important;
}

.header {
  padding: 0 var(--space-lg);
  height: 70px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  align-items: center;
  justify-content: center;
  background-color: var(--content-bg);
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1;
}
.header .hd-btn {
  margin: 0 var(--space);
}
.header .hd-links {
  margin: 0 var(--space-lg);
  font-family: var(--bold-font);
}
.header .hd-links .link * {
  color: var(--color);
}
.header .hd-links .link:hover * {
  color: var(--brand-color);
}
.header .link-active .click {
  background: var(--light-brand-color) !important;
}
.header .link-active .click * {
  color: var(--brand-color) !important;
}

.content {
  width: var(--content-width);
  background: green;
  height: 100vh;
  margin: auto;
}

.hero-container {
  padding-bottom: var(--header-height);
  border-bottom-left-radius: 55% 5%;
  border-bottom-right-radius: 55% 5%;
  margin-top: var(--header-height);
}
.hero-container .hero-content {
  justify-content: center;
  align-items: center;
  margin: auto;
  height: 100%;
  display: flex;
  padding-top: 55px;
}
.hero-container .hero-left {
  width: 350px;
  height: 100%;
  padding-right: var(--space-lg);
  flex-direction: column;
  align-items: center;
}
.hero-container .hero-right,
.hero-container .hero-left {
  height: 100%;
}
.hero-container .hero-right {
  width: 630px;
  display: flex;
  justify-content: flex-end;
}
.hero-container .hero-title {
  font-size: 25px;
  font-family: var(--extreme-bold-font);
  color: var(--bold-color);
  text-align: center;
}
.hero-container .hero-title span {
  color: var(--brand-color);
}
.hero-container .hero-subtitle {
  color: var(--color-light);
  padding: var(--space-md) 0;
  text-align: center;
}
.hero-container .hero-subtitle b {
  font-family: var(--bold-font);
}
.hero-container .hero-subtitle span {
  color: var(--brand-color);
}
.hero-container .hero-actions {
  display: flex;
}
.hero-container .hero-graphic {
  width: 500px;
  height: 500px;
  border-radius: 100px;
  box-shadow: var(--shadow-thick);
  overflow: hidden;
}
.hero-container .hero-graphic .logo {
  height: calc(100% - 100px);
}
.hero-container .hero-graphic .inside {
  width: calc(100% - 50px);
  height: calc(100% - 50px);
}
.hero-container .hero-graphic.small {
  width: 300px;
  height: 300px;
  border-radius: 50px;
}
.hero-container .hero-comments {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.hero-container .hero-comments .cm-divider {
  height: 25px;
  width: 3px;
  background: var(--border-color);
}
.hero-container .hero-comments .list-item {
  width: 100%;
  box-shadow: var(--shadow);
  margin-bottom: 0;
  border-radius: var(--radius);
}
.hero-container .hero-comments .list-left {
  border-right: 3px solid var(--brand-color);
  margin-right: var(--space);
}
.hero-container .hero-comments .hh .vertical {
  align-items: flex-start;
}
.hero-container .hero-label {
  border-radius: var(--radius);
  height: 34px;
  padding: 5px var(--space-md);
  padding-right: 2px;
  box-shadow: var(--shadow);
  background: var(--white);
  margin-bottom: var(--space-md);
  font-family: var(--bold-font);
  color: var(--brand-color);
}
.hero-container .hero-label .icon {
  margin-left: var(--space-md);
  width: 30px;
  height: 30px;
  border-radius: 100%;
  box-shadow: var(--shadow-thick);
}

.get-btn {
  height: 30px;
  overflow: hidden;
  cursor: pointer;
}
.get-btn img,
.get-btn svg {
  height: 100%;
}

.hero-container.flip .hero-content {
  display: flex;
  flex-direction: row-reverse;
}
.hero-container.flip .hero-right {
  justify-content: flex-start;
}

.hero-container.curved {
  border-top: 1px solid var(--border-color);
  border-top-left-radius: 55% 5%;
  border-top-right-radius: 55% 5%;
  padding: 100px 0;
}
.hero-container.curved .hero-content {
  padding: 0;
}

.section {
  width: 100%;
}
.section .wave {
  transform: rotate(180deg);
}
.section .wave * {
  fill: var(--background);
}

.features-wrapper {
  border-bottom: 1px solid var(--border-color);
  border-bottom-left-radius: 55% 5%;
  border-bottom-right-radius: 55% 5%;
  background: var(--background);
  padding-bottom: 100px;
}

.testimonials {
  margin-top: 40px;
  margin-bottom: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.testimonials h3 {
  text-align: center;
  font-size: 25px;
  font-family: var(--extreme-bold-font);
}
.testimonials .testi-list {
  display: grid;
  grid-template-columns: repeat(2, 350px);
  gap: 15px;
}
.testimonials .list-item.testim {
  background: var(--tintBackgroundColor);
  display: inline-block;
  cursor: default;
}

.mobile-only {
  display: none;
}

.fancy-feature-hero {
  width: var(--content-width);
  height: 200px;
  border-radius: var(--radius);
  border: 3px solid var(--blue);
  border-style: dotted;
  margin: auto;
}
.fancy-feature-hero .fancy-card {
  width: 300px;
  margin: auto;
}
.fancy-feature-hero .fancy-card .fn-ft-title {
  font-size: 25px;
  font-family: var(--extreme-bold-font);
  color: var(--bold-color);
  text-align: center;
}
.fancy-feature-hero .fancy-card .fn-ft-title span {
  color: var(--brand-color);
}
.fancy-feature-hero .fancy-card .fn-ft-subtitle {
  color: var(--color-light);
  padding: var(--space-md) 0;
  text-align: center;
}
.fancy-feature-hero .fancy-card .fn-ft-subtitle b {
  font-family: var(--bold-font);
}
.fancy-feature-hero .fancy-card .fn-ft-subtitle span {
  color: var(--brand-color);
}
.fancy-feature-hero.bottom {
  border-top: none;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.fancy-feature-hero.top {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom: none;
}

.fancy-feature-connector {
  margin: auto;
  width: 40px;
  flex-direction: column;
  margin-bottom: 40px;
}
.fancy-feature-connector .fancy-feature-line {
  width: 3px;
  height: 80px;
  border-right: 3px solid transparent;
  border-left: 3px solid var(--blue);
  border-style: dotted;
  background-clip: padding-box;
}
.fancy-feature-connector .fancy-feature-dot {
  width: 40px;
  height: 40px;
  border-radius: var(--circle);
  background: var(--blue);
}

.fancy-feature-connector.flip {
  flex-direction: column-reverse;
  margin-top: 40px;
  margin-bottom: 0;
}

.features {
  display: grid;
  grid-template-columns: repeat(3, 300px);
  justify-content: center;
  width: 100%;
  gap: 50px;
  margin-bottom: 80px;
}
.features .feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 300px;
}
.features .feature .ft-graphic {
  height: 180px;
  width: 180px;
}
.features .feature .ft-title {
  font-family: var(--bold-font);
  font-size: 16px;
  color: var(--bold-color);
  padding: var(--space);
}
.features .feature .ft-subtitle {
  color: var(--color-light);
}

.list-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 500px;
}
.list-card .graphic {
  height: 180px;
  width: 180px;
}
.list-card .title {
  font-family: var(--bold-font);
  font-size: 16px;
  color: var(--bold-color);
  padding: var(--space);
}
.list-card .subtitle {
  color: var(--color-light);
}
.list-card .subtitle span {
  color: var(--black);
}
.list-card .action {
  padding-top: var(--space-md);
}

.job {
  padding: 80px 0;
}

.appointment {
  margin-top: var(--header-height);
}
.appointment .apt-title {
  font-size: 45px;
  width: calc(100% - 50px);
  font-family: var(--extreme-bold-font);
  color: var(--white);
  text-align: center;
  margin: auto;
  background: var(--background);
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--radius);
  margin-top: 80px;
  margin-bottom: 30px;
  padding: 20px;
  background: linear-gradient(90deg, hsl(248, 21%, 15%) 0%, hsl(250, 65%, 6%) 100%);
  background: -webkit-linear-gradient(90deg, hsl(248, 21%, 15%) 0%, hsl(250, 65%, 6%) 100%);
}

.brag {
  margin-top: 50px;
}
.brag .br-title {
  font-size: 45px;
  max-width: 520px;
  width: calc(100% - 20px);
  font-family: var(--extreme-bold-font);
  color: var(--bold-color);
  text-align: center;
  margin: auto;
}
.brag .br-title span {
  color: var(--brand-color);
}
.brag .br-subtitle {
  text-align: center;
  color: var(--color-light);
  padding: var(--space-lg);
}
.brag .br-button-content {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.brag .br-graphic {
  max-width: 400px;
  margin: auto;
  background: var(--background);
  border-radius: var(--radius);
  margin-top: 40px;
}
.brag .br-steps {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.brag .br-step {
  display: flex;
  margin-bottom: var(--space-md);
}
.brag .br-step-icon {
  width: 30px;
}

.about {
  margin-top: var(--header-height);
  padding: var(--space-md);
  text-align: center;
  min-height: 100vh;
}
.about .abt-sec {
  margin: auto;
  max-width: 600px;
  margin-bottom: 40px;
}
.about h2 {
  font-family: var(--bold-font);
}
.about b {
  font-family: var(--extreme-bold-font);
}

.contact {
  margin-top: var(--header-height);
  background: var(--background);
}
.contact .ct-content {
  margin-top: 100px;
  margin-bottom: 50px;
  padding-bottom: 40px;
}
.contact h3 {
  padding: var(--space-lg) 0;
  text-align: center;
}
.contact .c-form {
  width: 300px;
  margin: 100px auto;
}
.contact .ct-exp {
  flex-direction: column;
}
.contact .ct-con-cp-list {
  display: flex;
  margin-top: 24px;
}
.contact .cp-li {
  border: 1px solid var(--border-color);
  padding: 5px 10px;
  border-radius: var(--radius);
}
.contact .cp-li:last-child {
  margin-left: var(--space);
}
.contact .ct-list {
  width: 200px;
  background: var(--tintBackgroundColor);
  margin-left: 30px;
  padding: var(--space);
  text-align: center;
  border-radius: var(--min-radius);
}
.contact .ct-list .hit {
  height: 100px;
}
.contact .ct-list .tit {
  font-family: "bold";
  padding-top: var(--space);
  padding-bottom: 3px;
}
.contact .ct-list .desc {
  color: var(--primary-coolor-light);
  font-size: 13px;
}
.contact .ct-list:first-child {
  margin-left: 0;
}
.contact .bt {
  font-size: 12.5px;
}
.contact .fdc {
  width: 100px;
  cursor: pointer;
  transition: all 0.3s;
  border: 3px solid var(--border-color);
  flex-direction: column;
}
.contact .fdc .graphic {
  width: 50px;
  height: 50px;
}
.contact .fdc .graphic img,
.contact .fdc .graphic svg {
  width: 100%;
  height: 100%;
}
.contact .fdc.checked {
  border: 3px solid var(--blue);
}
.contact .fdc:hover {
  opacity: 0.8;
}

.wel-section {
  width: 100%;
}

.al-banner {
  height: 250px;
  margin-top: var(--header-height);
  border-bottom: 1px solid var(--border-color);
  border-bottom-left-radius: 55% 5%;
  border-bottom-right-radius: 55% 5%;
  background: var(--background);
  margin-bottom: var(--space-lg);
}
.al-banner h2 {
  font-family: var(--bold-font);
  font-size: 30px;
  color: var(--blue);
}

.readable .tt-content {
  width: calc(100% - 20px);
  max-width: 600px;
  margin: auto;
}

.money {
  width: 100%;
  height: 60px;
  border-radius: 4px;
  background: #7c3a36;
  margin-top: var(--space);
  flex-direction: column;
  display: flex;
  justify-content: space-between;
}
.money .mn-cash {
  color: var(--white);
  padding: 5px;
  font-size: 11px;
}
.money .mbn-val {
  font-family: var(--bold-font);
  font-size: 20px;
  padding-left: 4px;
}
.money .mn-bottom {
  border-top: 1px solid rgba(255, 245, 219, 0.3490196078);
  font-size: var(--small-font-size);
  color: var(--white);
  padding: 5px 8px;
}

.footer {
  padding: var(--space-lg);
  border-top-left-radius: 95% 27%;
  border-top-right-radius: 95% 27%;
  border-top: 1px solid var(--border-color);
  margin-top: var(--space-lg);
}
.footer .ft-content {
  font-size: var(--medium-font-size);
}
.footer .ft-content .click.link {
  color: var(--color-light);
}
.footer .socials .click {
  margin: 0 5px;
  padding: 7px;
}

@media screen and (max-width: 1030px) {
  :root {
    --content-width: calc(100% - var(--space-md));
  }
  .hero-container .hero-left,
  .hero-container.flip .hero-left {
    padding: 0;
    margin-right: 40px;
  }
  .hero-container .hero-right,
  .hero-container.flip .hero-right {
    width: 400px;
    height: 400px;
    justify-content: center;
  }
  .hero-container .hero-right .hero-graphic,
  .hero-container.flip .hero-right .hero-graphic {
    width: auto;
    height: unset;
  }
  .features {
    display: grid;
    grid-template-columns: repeat(2, 300px);
  }
  .fancy-feature-hero {
    width: 500px;
  }
  .contact .ct-list {
    width: 100%;
  }
  .contact .ct-list .hit {
    height: 120px;
  }
}
@media screen and (max-width: 1000px) {
  :root {
    --content-width: calc(100% - var(--space-md));
  }
  .hero-container .hero-content,
  .hero-container.flip .hero-content {
    flex-direction: column;
  }
  .hero-container .hero-left,
  .hero-container.flip .hero-left {
    margin: 0;
  }
  .hero-container .hero-right,
  .hero-container.flip .hero-right {
    width: auto;
    margin-top: 50px;
    justify-content: center;
  }
  .hero-container .hero-right .hero-graphic,
  .hero-container.flip .hero-right .hero-graphic {
    width: 500px;
    height: 500px;
  }
  .features {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
  }
  .fancy-feature-hero {
    width: 500px;
  }
  .contact .ct-list {
    width: 100%;
  }
  .contact .ct-list .hit {
    height: 120px;
  }
}
@media screen and (max-width: 800px) {
  .testimonials .testi-list {
    display: grid;
    grid-template-columns: 100%;
    gap: 15px;
  }
  .testimonials .list-item.testim {
    width: calc(100% - 20px);
    margin: auto;
  }
}
@media screen and (max-width: 700px) {
  .mobile-link {
    display: none;
  }
  .hero-container .hero-right,
  .hero-container.flip .hero-right {
    width: auto;
  }
  .hero-container .hero-right .hero-graphic,
  .hero-container.flip .hero-right .hero-graphic {
    width: 400px;
    height: 400px;
  }
}
@media screen and (max-width: 600px) {
  .hero-container .hero-graphic,
  .hero-container.flip .hero-graphic {
    width: calc(100% - 30px) !important;
    height: auto;
    border-radius: 50px;
  }
  .hero-container .hero-left,
  .fancy-feature-hero .fancy-card {
    width: calc(100% - 20px);
  }
  .features-wrapper {
    padding-top: 30px;
  }
  .header .hd-links {
    margin: 0;
  }
  .header .hd-bucket {
    width: 100%;
    max-width: unset;
  }
  .mob-nav {
    display: none;
  }
  .fancy-feature-hero {
    width: calc(100% - 30px);
  }
  .footer {
    height: 140px !important;
  }
  .footer .socials {
    margin: 0;
    margin-top: 10px;
  }
  .footer .ft-content {
    flex-direction: column;
  }
  .contact .ct-con-lists {
    display: grid;
    grid-template-columns: 150px;
    gap: 15px;
  }
  .contact .ct-con-cp-list {
    display: block;
    text-align: center;
  }
  .contact .ct-con-cp-list .cp-li {
    margin: 0;
    margin-bottom: 10px;
  }
  .contact .ct-list {
    margin: 0;
  }
}
@media screen and (max-width: 500px) {
  .mobile-only {
    display: block;
  }
  .appointment .apt-title {
    font-size: 30px;
  }
}/*# sourceMappingURL=main.css.map */