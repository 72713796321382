@font-face {
    font-family: "brand-font";
    src: url("../assets/fonts/COOPBL.ttf");
}

@font-face {
    font-family: "reg";
    src: url("../assets/fonts/Inter-Regular.ttf");
}

@font-face {
    font-family: "bold";
    src: url("../assets/fonts/Inter-Bold.ttf");
}

@font-face {
    font-family: "extreme-bold";
    src: url("../assets/fonts/Inter-ExtraBold.ttf");
}

:root {
    // fonts
    --light-font: "light";
    --regular-font: "reg";
    --semi-bold-font: "semi-bold";
    --bold-font: "bold";
    --extreme-bold-font: "extreme-bold";
    --brand-font: 'brand-font';

    // colors
    --brand-color: #007aff;
    --light-brand-color: #ecf2ff;
    --blue: #007aff;
    --white: white;
    --black: black;

    // shadows and borders
    --shadow: rgba(0, 0, 0, 0.12) 0 0 16px 0;
    --light-borded-shadow: rgba(0, 0, 0, 0.6) 0 0 2px 0;
    --shadow-thick: rgba(0, 0, 0, 0.3) 0 0 16px 0;

    // sizes
    // fonts
    --font-size: 14px;
    --font-lg: 30px;
    --medium-font-size: 12.5px;
    --small-font-size: 10px;

    --header-width: 100%;
    --header-height: 70px;
    --tab-bar-height: 40px;
    --content-width: 980px;
    --scrollbar-width: 0.6vw;
    --circle: 50%;
    --space: 10px;
    --space-md: 15px;
    --space-lg: 20px;
    --logo-size: 50px;
    --min-radius: 8px;
    --radius: 16px;

    --app-logo-size: 45px;
    --small-width: 200px;
}

.light {
    --background: #ffffff;
    --content-bg: #f9f9fd;
    --background-shade: #fafafa;
    --tintBackgroundColor: #e9ecef;
    --color: #535c65; // 343a40
    --color-light: #8896a5;
    --bold-color: black;
    --shade: #e8e8e8;
    --tintBorderColor: #dee2e6;
    --pressHiglight: #f3f3f3;
    --border-color: #e9ecef;
    --placeholder: #9c9c9c;
    --sidebar-bg: #ffffff;
    --hover-bg: #f7f7f7;
    --hover-color: var(--color);
    --active-btn-bg: #ecf6ff;
    --blue-tint: #ecf6ff;
    --scrollbar-thumb-color: #999999;
    --focused-input-color: #a5c9f5;
    --search-input-bg: #eeeef3;
    --green: #4ed164;
    --red: #ff5d5d;
    --light-red: #ff9898;
}

.dark {
    --background: #2a3139;
    --content-bg: #323d48;
    --background-shade: #1a1b1d;
    --tintBackgroundColor: #22282f;
    --color: white;
    --bold-color: white;
    --border-color-2: #3e3e3e;
    --color-light: #cdcdcd;
    --shade: #343a40;
    --tintBorderColor: #495057;
    --pressHiglight: #1b1e22;
    --border-color: #343a40;
    --placeholder: #8a8a8a;
    --sidebar-bg: #1f2326;
    --hover-bg: rgb(42, 42, 42);
    --hover-color: var(--color);
    --active-btn-bg: #ecf6ff;
    --blue-tint: #1e2b36;
    --scrollbar-thumb-color: #454d54;
    --focused-input-color: #5b6f89;
    --search-input-bg: #282c30;
    --green: #4ed164;
    --red: #c76262;
    --light-red: #ff9898;
    --light-brand-color: #282f3d;
}

%center {
    display: flex;
    justify-content: center;
    align-items: center;
}

%center-horizontal {
    display: flex;
    justify-content: center;
}

%center-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

%center-vertical {
    display: flex;
    align-items: center;
}

%vertical {
    display: flex;
    align-items: center;
}

%modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

%content {
    width: var(--content-width);
    max-width: var(--content-width);
}

%container {
    cursor: default;
}

body {
    font-family: var(--regular-font);
    font-size: 14px;
    background: var(--content-bg);
    color: var(--color);
    margin: 0;
    scrollbar-width: var(--scrollbar-width);
    scrollbar-color: var(--tintBackgroundColor) var(--pressHiglight);
    overflow-x: hidden;
}

::-webkit-scrollbar {
    width: var(--scrollbar-width);
}

::-webkit-scrollbar-thumb {
    background: var(--scrollbar-thumb-color);
    border-radius: var(--min-radius);
    border: 2px solid var(--content-bg);
}

::-webkit-scrollbar-track {
    background: transparent;
}

* {
    box-sizing: border-box;
    text-decoration: none;
}

a {
    text-decoration: none;
    color: inherit;
}

.flex {
    display: flex;
}

.center {
    @extend %center;
}

.logo {
    @extend %center-vertical;

    .logo-text {
        font-family: var(--brand-font);
        color: var(--blue);
        user-select: none;
    }
}

.list-item {
    padding: var(--space);
    display: flex;
    align-items: center;
    border-radius: var(--min-radius);
    justify-content: space-between;
    background: var(--background);

    .list-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
    }

    .vertical {
        width: 100%;
    }

    .li-title {
        font-family: var(--bold-font);
        color: var(--bold-color);

        span {
            color: #ac8c0c;
        }
    }

    .list-right {
        padding-left: var(--space);
    }

    .list-left {
        padding-right: var(--space);
        @extend %vertical;
    }

    .li-subtitle-text {
        font-size: var(--medium-font-size);
        color: var(--color-light);
        @extend %vertical;
    }
}

.list-item.testim {
    img {
        filter: invert(1);
    }
}

.list-item.clickable {
    cursor: pointer;
    transition: all 0.3s;
}

.list-item.clickable:hover {
    background: var(--background-shade);
}

.click {
    cursor: pointer;
    padding: var(--space);
    @extend %center-vertical;
    transition: all 0.3s;
    user-select: none;

    .clk-icon {
        @extend %center-vertical;
    }
}

.click:hover {
    transition: all 0.3s;
    opacity: 0.7 !important;
}

.click.link {
    color: var(--blue);

    svg {
        color: var(--blue);
    }
}

.click.tint {
    color: var(--blue);
    background: var(--blue-tint);
    border-radius: var(--min-radius);

    svg {
        color: var(--blue) !important;
    }
}

.click.regular {
    color: var(--color);

    svg {
        color: var(--color);
    }
}

.click.navlink {
    padding: var(--space-md) 10px;
    color: var(--color-light);
    border-radius: var(--min-radius);
    font-size: 12.5px;
    margin-bottom: var(--space);

    svg {
        path {
            stroke: var(--color-light);
        }
    }
}

.click.navlink:hover {
    background: var(--hover-bg);
}

.link-active {
    .click {
        background: var(--blue-tint) !important;
        border-radius: var(--min-radius);

        * {
            color: var(--blue) !important;
        }
    }
}

.button {
    padding: var(--space);
    cursor: pointer;
    transition: all;
    user-select: none;
    @extend %center;
    font-size: var(--medium-font-size);
    transition: all 0.3s;
}

.button:active {
    scale: 1.09;
}

.button:hover {
    opacity: 0.8;
}

.button.solid {
    background-color: var(--blue);
    border-radius: var(--min-radius);
    font-size: 12px;
    color: var(--white);
    font-family: var(--bold-font);

    svg {
        color: var(--white);
    }
}

.button.link {
    color: var(--blue);
    font-family: var(--bold-font);
    font-size: 13.5px;

    svg {
        path {
            fill: var(--blue);
        }
    }
}

.btn-icon {
    background: var(--background);
    border-radius: var(--circle);
    border: 1px solid var(--border-color);
}

.scflogo {
    @extend %center;
    justify-content: flex-start;
    height: calc(var(--header-height) - 40px);

    img {
        height: 100%;
    }
}

.input-container {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.17);
    max-width: 600px;
    border-radius: var(--min-radius);
    overflow: hidden;
    padding: 0 var(--space);
    background: var(--background);
    @extend %vertical;
    border: 2px solid var(--border-color);

    .input-left,
    .input-right {
        @extend %center-vertical;
    }

    .input-left {
        padding-right: var(--space);
    }

    .input-right {
        padding-left: var(--space);
    }

    input {
        outline: none;
        border: 0;
        font-family: var(--regular-font);
        padding: var(--space) 0;
        width: 100%;
        background: transparent;
        color: var(--bold-color);
    }
}

.input-container:focus-within {
    border: 2px solid var(--brand-color);
}

.avatar {
    background: var(--tintBackgroundColor);
    border-radius: var(--circle);
    position: relative;

    .av-image {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        border-radius: var(--circle);
        overflow: hidden;

        img {
            width: 100%;
        }
    }

    .av-placeholder {
        width: 100%;
        height: 100%;
        border-radius: var(--circle);
        @extend %center;
    }

    .av-title {
        font-family: "bold";
        text-align: center;
        text-transform: uppercase;
    }
}

.badge {
    width: 45px;
    height: 45px;
    background: var(--blue);
    border-radius: var(--min-radius);
    @extend %center;
}

.modal {
    @extend %modal;
    z-index: 100;
}

.center {
    @extend %center;
}

.blur-bg {
    backdrop-filter: blur(10px);
}

.transparent-bg {
    background: rgba(0, 0, 0, 0.6);
}

.menu-item {
    background: transparent;
    user-select: none;

    .li-subtitle-text {
        font-size: var(--medium-font-size);
        padding-top: 5px;
    }
}

.menu-link {
    * {
        color: var(--blue) !important;
    }

    padding-top: 0;
    padding-bottom: 0;
    height: 35px;
    overflow: hidden;
}

.header {
    padding: 0 var(--space-lg);
    height: 70px;
    @extend %center-vertical, %container;
    user-select: none;
    align-items: center;
    justify-content: center;
    background-color: var(--content-bg);
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 1;

    .hd-bucket {
        @extend %center-between, %content;
    }

    .hd-left {
        @extend %center-vertical;
    }

    .hd-btn {
        margin: 0 var(--space);
    }

    .hd-links {
        margin: 0 var(--space-lg);
        font-family: var(--bold-font);

        .link {
            * {
                color: var(--color);
            }
        }

        .link:hover {
            * {
                color: var(--brand-color);
            }
        }

    }

    .link-active {
        .click {
            background: var(--light-brand-color) !important;

            * {
                color: var(--brand-color) !important;
            }
        }
    }
}

.content {
    width: var(--content-width);
    background: green;
    height: 100vh;
    margin: auto;
}

.hero-container {
    padding-bottom: var(--header-height);
    // border-bottom: 1px solid var(--border-color);
    border-bottom-left-radius: 55% 5%;
    border-bottom-right-radius: 55% 5%;
    @extend %container;
    margin-top: var(--header-height);

    .hero-content {
        @extend %content;
        justify-content: center;
        align-items: center;
        margin: auto;
        height: 100%;
        display: flex;
        padding-top: 55px;
    }

    .hero-left {
        width: 350px;
        height: 100%;
        padding-right: var(--space-lg);
        @extend %center;
        flex-direction: column;
        align-items: center;
    }

    .hero-right,
    .hero-left {
        height: 100%;
    }

    .hero-right {
        width: 630px;
        display: flex;
        justify-content: flex-end;
    }

    .hero-title {
        font-size: 25px;
        font-family: var(--extreme-bold-font);
        color: var(--bold-color);
        text-align: center;

        span {
            color: var(--brand-color);
        }
    }

    .hero-subtitle {
        color: var(--color-light);
        padding: var(--space-md) 0;
        text-align: center;

        b {
            font-family: var(--bold-font);
        }

        span {
            color: var(--brand-color);
        }
    }

    .hero-actions {
        display: flex;
    }


    .hero-graphic {
        width: 500px;
        height: 500px;
        border-radius: 100px;
        box-shadow: var(--shadow-thick);
        @extend %center;
        overflow: hidden;

        .logo {
            height: calc(100% - 100px);
        }

        .inside {
            width: calc(100% - 50px);
            height: calc(100% - 50px);
        }
    }

    .hero-graphic.small {
        width: 300px;
        height: 300px;
        border-radius: 50px;
    }

    .hero-comments {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .cm-divider {
            height: 25px;
            width: 3px;
            background: var(--border-color);
        }

        .list-item {
            width: 100%;
            box-shadow: var(--shadow);
            margin-bottom: 0;
            border-radius: var(--radius);
        }

        .list-left {
            border-right: 3px solid var(--brand-color);
            margin-right: var(--space);
        }

        .hh .vertical {
            align-items: flex-start;
        }
    }

    .hero-label {
        border-radius: var(--radius);
        @extend %center-vertical;
        height: 34px;
        padding: 5px var(--space-md);
        padding-right: 2px;
        box-shadow: var(--shadow);
        background: var(--white);
        margin-bottom: var(--space-md);
        font-family: var(--bold-font);
        color: var(--brand-color);

        .icon {
            @extend %center;
            margin-left: var(--space-md);
            width: 30px;
            height: 30px;
            border-radius: 100%;
            box-shadow: var(--shadow-thick);
        }
    }
}

.get-btn {
    height: 30px;
    overflow: hidden;
    cursor: pointer;

    img,
    svg {
        height: 100%;
    }
}

.hero-container.flip {
    .hero-content {
        display: flex;
        flex-direction: row-reverse;
    }

    .hero-right {
        justify-content: flex-start;
    }
}

.hero-container.curved {
    border-top: 1px solid var(--border-color);
    border-top-left-radius: 55% 5%;
    border-top-right-radius: 55% 5%;
    padding: 100px 0;

    .hero-content {
        padding: 0;
    }
}

.section {
    width: 100%;

    .wave {
        transform: rotate(180deg);

        * {
            fill: var(--background);
            // fill: #120a33;
        }
    }
}


.features-wrapper {
    border-bottom: 1px solid var(--border-color);
    border-bottom-left-radius: 55% 5%;
    border-bottom-right-radius: 55% 5%;
    background: var(--background);
    padding-bottom: 100px;
}

.testimonials {
    margin-top: 40px;
    margin-bottom: 100px;
    display: flex;
    align-items: center;
    flex-direction: column;

    h3 {
        text-align: center;
        font-size: 25px;
        font-family: var(--extreme-bold-font);
    }

    .testi-list {
        display: grid;
        grid-template-columns: repeat(2, 350px);
        gap: 15px;
    }

    .list-item.testim {
        background: var(--tintBackgroundColor);
        display: inline-block;
        cursor: default;
    }
}

.mobile-only {
    display: none;
}

.fancy-feature-hero {
    width: var(--content-width);
    height: 200px;
    border-radius: var(--radius);
    border: 3px solid var(--blue);
    border-style: dotted;
    margin: auto;
    @extend %center;

    .fancy-card {
        width: 300px;
        margin: auto;

        .fn-ft-title {
            font-size: 25px;
            font-family: var(--extreme-bold-font);
            color: var(--bold-color);
            text-align: center;

            span {
                color: var(--brand-color);
            }
        }

        .fn-ft-subtitle {
            color: var(--color-light);
            padding: var(--space-md) 0;
            text-align: center;

            b {
                font-family: var(--bold-font);
            }

            span {
                color: var(--brand-color);
            }
        }

        .fn-actions {
            @extend %center;
        }
    }
}

.fancy-feature-hero.bottom {
    border-top: none;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.fancy-feature-hero.top {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom: none;
}

.fancy-feature-connector {
    margin: auto;
    width: 40px;
    @extend %center;
    flex-direction: column;
    margin-bottom: 40px;

    .fancy-feature-line {
        width: 3px;
        height: 80px;
        border-right: 3px solid transparent;
        border-left: 3px solid var(--blue);
        border-style: dotted;
        background-clip: padding-box;
    }

    .fancy-feature-dot {
        width: 40px;
        height: 40px;
        border-radius: var(--circle);
        background: var(--blue);
    }
}

.fancy-feature-connector.flip {
    flex-direction: column-reverse;
    margin-top: 40px;
    margin-bottom: 0;
}

.features {
    display: grid;
    grid-template-columns: repeat(3, 300px);
    justify-content: center;
    width: 100%;
    gap: 50px;
    margin-bottom: 80px;

    .feature {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 100%;
        max-width: 300px;

        .ft-graphic {
            height: 180px;
            width: 180px;
            @extend %center;
        }

        .ft-title {
            font-family: var(--bold-font);
            font-size: 16px;
            color: var(--bold-color);
            padding: var(--space);
        }

        .ft-subtitle {
            color: var(--color-light);
        }
    }
}

.list-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    max-width: 500px;

    .graphic {
        height: 180px;
        width: 180px;
        @extend %center;
    }

    .title {
        font-family: var(--bold-font);
        font-size: 16px;
        color: var(--bold-color);
        padding: var(--space);
    }

    .subtitle {
        color: var(--color-light);

        span {
            color: var(--black);
        }
    }

    .action {
        padding-top: var(--space-md)
    }
}

.job {
    @extend %center;
    padding: 80px 0;
}

.appointment {
    @extend %container;
    margin-top: var(--header-height);

    .apt-title {
        font-size: 45px;
        width: calc(100% - 50px);
        font-family: var(--extreme-bold-font);
        color: var(--white);
        text-align: center;
        margin: auto;
        background: var(--background);
        height: 180px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: var(--radius);
        margin-top: 80px;
        margin-bottom: 30px;
        padding: 20px;

        background: linear-gradient(90deg, hsl(248deg, 21%, 15%) 0%, hsl(250deg 65% 6%) 100%);

        background: -moz-linear-gradient(90deg, hsl(248deg, 21%, 15%) 0%, hsl(250deg 65% 6%) 100%);

        background: -webkit-linear-gradient(90deg, hsl(248deg, 21%, 15%) 0%, hsl(250deg 65% 6%) 100%);

    }
}

.brag {
    margin-top: 50px;

    .br-title {
        font-size: 45px;
        max-width: 520px;
        width: calc(100% - 20px);
        font-family: var(--extreme-bold-font);
        color: var(--bold-color);
        text-align: center;
        margin: auto;

        span {
            color: var(--brand-color);
        }
    }

    .br-subtitle {
        text-align: center;
        color: var(--color-light);
        padding: var(--space-lg);
    }

    .br-button-content {
        display: flex;
        justify-content: center;
        margin-top: 30px;
    }

    .br-graphic {
        max-width: 400px;
        margin: auto;
        background: var(--background); // #222529
        border-radius: var(--radius);
        margin-top: 40px;
    }

    .br-steps {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .br-step {
        display: flex;
        margin-bottom: var(--space-md);
    }

    .br-step-icon {
        width: 30px;
    }
}

.about {
    margin-top: var(--header-height);
    padding: var(--space-md);
    text-align: center;
    min-height: 100vh;

    .abt-sec {
        margin: auto;
        max-width: 600px;
        margin-bottom: 40px;
    }

    h2 {
        font-family: var(--bold-font);
    }
    b {
        font-family: var(--extreme-bold-font);
    }
}

.contact {
    margin-top: var(--header-height);
    background: var(--background);

    .ct-content {
        margin-top: 100px;
        margin-bottom: 50px;
        padding-bottom: 40px;
    }

    h3 {
        padding: var(--space-lg) 0;
        text-align: center;
    }

    .c-form {
        width: 300px;
        margin: 100px auto;
    }

    .ct-exp {
        @extend %center;
        flex-direction: column;
    }

    .ct-con-lists {
        @extend %center;
        // padding-bottom: 100px;
    }

    .ct-con-cp-list {
        display: flex;
        margin-top: 24px;
    }

    .cp-li {
        border: 1px solid var(--border-color);
        padding: 5px 10px;
        border-radius: var(--radius);

        &:last-child {
            margin-left: var(--space);
        }
    }

    .ct-list {
        width: 200px;
        background: var(--tintBackgroundColor);
        margin-left: 30px;
        padding: var(--space);
        text-align: center;
        border-radius: var(--min-radius);

        // @extend %center;
        // flex-direction: column;
        .hit {
            height: 100px;
        }

        .tit {
            font-family: "bold";
            padding-top: var(--space);
            padding-bottom: 3px;
        }

        .desc {
            color: var(--primary-coolor-light);
            font-size: 13px;
        }
    }

    .ct-list:first-child {
        margin-left: 0;
    }

    .bt {
        font-size: 12.5px;
    }

    .fdc {
        width: 100px;
        cursor: pointer;
        transition: all 0.3s;
        border: 3px solid var(--border-color);
        @extend %center;
        flex-direction: column;

        .graphic {
            width: 50px;
            height: 50px;

            img,
            svg {
                width: 100%;
                height: 100%;
            }
        }
    }

    .fdc.checked {
        border: 3px solid var(--blue);
    }

    .fdc:hover {
        opacity: 0.8;
    }
}


.wel-section {
    width: 100%;
}

.al-banner {
    height: 250px;
    margin-top: var(--header-height);
    @extend %center;
    border-bottom: 1px solid var(--border-color);
    border-bottom-left-radius: 55% 5%;
    border-bottom-right-radius: 55% 5%;
    background: var(--background);
    margin-bottom: var(--space-lg);

    h2 {
        font-family: var(--bold-font);
        font-size: 30px;
        color: var(--blue);
    }
}


.readable {
    .tt-content {
        width: calc(100% - 20px);
        max-width: 600px;
        margin: auto;
    }
}

.money {
    width: 100%;
    height: 60px;
    border-radius: 4px;
    background: #7c3a36;
    margin-top: var(--space);
    flex-direction: column;
    display: flex;
    justify-content: space-between;

    .mn-cash {
        @extend %center-vertical;
        color: var(--white);
        padding: 5px;
        font-size: 11px;
    }

    .mbn-val {
        font-family: var(--bold-font);
        font-size: 20px;
        padding-left: 4px;
    }

    .mn-bottom {
        border-top: 1px solid #fff5db59;
        font-size: var(--small-font-size);
        color: var(--white);
        padding: 5px 8px;
    }
}

.footer {
    padding: var(--space-lg);
    border-top-left-radius: 95% 27%;
    border-top-right-radius: 95% 27%;
    border-top: 1px solid var(--border-color);
    margin-top: var(--space-lg);

    .ft-content {
        @extend %center;
        font-size: var(--medium-font-size);

        .click.link {
            color: var(--color-light);
        }
    }

    .socials {
        .click {
            margin: 0 5px;
            padding: 7px;
        }
    }
}



@media screen and (max-width: 1030px) {
    :root {
        --content-width: calc(100% - var(--space-md));
    }

    .hero-container,
    .hero-container.flip {
        .hero-left {
            padding: 0;
            margin-right: 40px;
        }

        .hero-right {
            width: 400px;
            height: 400px;
            justify-content: center;

            .hero-graphic {
                width: auto;
                height: unset;
            }
        }
    }

    .features {
        display: grid;
        grid-template-columns: repeat(2, 300px);
    }

    .fancy-feature-hero {
        width: 500px;
    }

    .contact {
        .ct-list {
            width: 100%;

            .hit {
                height: 120px;
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    :root {
        --content-width: calc(100% - var(--space-md));
    }

    .hero-container,
    .hero-container.flip {
        .hero-content {
            flex-direction: column;
        }

        .hero-left {
            margin: 0;
        }

        .hero-right {
            width: auto;
            margin-top: 50px;
            justify-content: center;

            .hero-graphic {
                width: 500px;
                height: 500px;
            }
        }
    }

    .features {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 20px;
    }

    .fancy-feature-hero {
        width: 500px;
    }

    .contact {
        .ct-list {
            width: 100%;

            .hit {
                height: 120px;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .testimonials {
        .testi-list {
            display: grid;
            grid-template-columns: 100%;
            gap: 15px;
        }

        .list-item.testim {
            width: calc(100% - 20px);
            margin: auto;
        }
    }
}

@media screen and (max-width: 700px) {
    .mobile-link {
        display: none;
    }

    .hero-container,
    .hero-container.flip {
        .hero-right {
            width: auto;

            .hero-graphic {
                width: 400px;
                height: 400px;
            }
        }
    }
}

@media screen and (max-width: 600px) {

    .hero-container,
    .hero-container.flip {
        .hero-graphic {
            width: calc(100% - 30px) !important;
            height: auto;
            border-radius: 50px;
        }
    }

    .hero-container .hero-left,
    .fancy-feature-hero .fancy-card {
        width: calc(100% - 20px);
    }

    .features-wrapper {
        padding-top: 30px;
    }

    .header {
        .hd-links {
            margin: 0;
        }

        .hd-bucket {
            width: 100%;
            max-width: unset;
        }
    }

    .mob-nav {
        display: none;
    }

    .fancy-feature-hero {
        width: calc(100% - 30px);
    }

    .footer {
        height: 140px !important;

        .socials {
            margin: 0;
            margin-top: 10px
        }

        .ft-content {
            @extend %center;
            flex-direction: column;
        }
    }

    .contact {
        .ct-con-lists {
            display: grid;
            grid-template-columns: 150px;
            gap: 15px;
        }

        .ct-con-cp-list {
            display: block;
                text-align: center;

            .cp-li {
                margin: 0;
                margin-bottom: 10px;
            
            }
        }

        .ct-list {
            margin: 0;
        }
    }
}

@media screen and (max-width: 500px) {
    .mobile-only {
        display: block;
    }

    .appointment .apt-title {
        font-size: 30px;
    }
}